import 'react-datepicker/dist/react-datepicker.css'
import 'react-tooltip/dist/react-tooltip.css'
import '../styles/tailwind.css'
import * as Sentry from '@sentry/node'
import App, { AppContext } from 'next/app'
import { UserContext } from '../src/contexts/UserContext'
import { NotificationContext } from '../src/contexts/NotificationContext'
import { NotificationListResponse, User } from '../src/services/types'
import { useState, useEffect } from 'react'
import Head from 'next/head'
import { Toaster } from 'react-hot-toast'
import { getCookie } from '../src/services/cookies'
import { addAuthorizationHeader } from '../src/utils/token'
import { UsersApi } from '../src/services/api/users'
import Router from 'next/router'
import NProgress from 'nprogress'
import { Tooltip } from 'react-tooltip'
import { colors } from '../styles/theme'

const tooltipStyle = {
  backgroundColor: colors.primary,
  color: colors.white,
  borderRadius: 5,
  fontSize: 12,
  padding: '4px 9px',
  fontWeight: 400,
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function MyApp({ Component, pageProps, err, userData, token }) {
  // Workaround for https://github.com/vercel/next.js/issues/8592
  const [user, setUser] = useState<User | null>(userData)
  const [notification, setNotification] =
    useState<NotificationListResponse | null>(null)

  useEffect(() => {
    token && addAuthorizationHeader(token)
  }, [token])

  useEffect(() => {
    setUser({ ...user, ...userData })
  }, [userData])

  return (
    <>
      <Toaster />
      <Tooltip id='banto-tooltip' style={tooltipStyle} />
      <Head>
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link
          rel='preconnect'
          href='https://fonts.gstatic.com'
          crossOrigin='anonymous'
        />
        <link
          href='https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap'
          rel='stylesheet'
        />
        <link rel='stylesheet' href='https://use.typekit.net/wri5dye.css' />
      </Head>
      <NotificationContext.Provider value={{ notification, setNotification }}>
        <UserContext.Provider value={{ user, setUser }}>
          <Component {...pageProps} err={err} />
        </UserContext.Provider>
      </NotificationContext.Provider>
    </>
  )
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext)
  const token = getCookie('token', appContext.ctx)
  const { pathname, res } = appContext.ctx
  let userData: User = null

  const redirectPage = (path: string) => {
    if (!pathname.includes(path)) {
      if (res) {
        res.writeHead(301, { Location: path })
        res.end()
      } else {
        Router.push(path)
      }
    }
  }

  const isRedirectToLoginPage =
    !(
      pathname === '/' ||
      pathname.includes('forgot-password') ||
      pathname.includes('features') ||
      pathname.includes('password-reset') ||
      pathname.includes('terms-and-conditions') ||
      pathname.includes('privacy-policy')
    ) && !pathname.includes('login')

  if (token) {
    addAuthorizationHeader(token)
    try {
      userData = await UsersApi.me()
    } catch (e) {
      if (isRedirectToLoginPage) {
        redirectPage('/login')
      }
    }
  } else {
    if (isRedirectToLoginPage) {
      redirectPage('/login')
    }
  }

  return { ...appProps, userData, token }
}

export default MyApp
